import React, { useEffect } from 'react';
import { Link } from 'react-scroll';
import Aos from 'aos'
import 'aos/dist/aos.css'


const Header = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
        }, [])
    
  const handleToggle = () => {
    document.getElementById('my-nav').classList.toggle('collapse');
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark bg-transparent" data-aos ="fade-down" id="gtco-main-nav">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src="assets/images/logo.png" alt="Logo" title="Logo" />
        </a>
        <button className="navbar-toggler" onClick={handleToggle}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div id="my-nav" className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link " to="#" smooth={true} duration={500} >
                <p className='header-link'>Home</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="services" smooth={true} duration={500}>
              <p className='header-link'>Services We Offer</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="why-us" smooth={true} duration={500}>
              <p className='header-link'>Why Us?</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="contact" smooth={true} duration={500}>
              <p className='header-link'>Contact</p>
              </Link>
            </li>
          </ul>
          <form className="form-inline my-2 my-lg-0">
            <a href="#" className="btn btn-outline-dark my-2 my-sm-0 mr-3 text-uppercase">
              Login
            </a>
            {/* <a href="#" className="btn btn-info my-2 my-sm-0 text-uppercase">
              Sign Up
            </a> */}
          </form>
        </div>
      </div>
    </nav>
  );
};

export default Header;
