import React, { useEffect, useRef } from 'react';
import './ImageCarousel.css';

const ImageCarousel = () => {
  const totalImages = 17;
  const [imagesToShow, setImagesToShow] = React.useState(5);
  const scrollRef = useRef(null);

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setImagesToShow(3);
    } else {
      setImagesToShow(5);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        const maxScrollLeft = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
        if (scrollRef.current.scrollLeft < maxScrollLeft) {
          scrollRef.current.scrollLeft += 1;
        } else {
          scrollRef.current.scrollLeft = 0;
        }
      }
    }, 10); // Adjust speed here by changing interval time

    return () => clearInterval(interval);
  }, []);

  const images = Array.from({ length: totalImages }).map((_, index) => (
    <div className="col" key={index}>
      <img
        src={`assets/images/images (${index + 1}).png`}
        className="img-fluid"
        alt={`Image ${index + 1}`}
      />
    </div>
  ));

  return (
    <div className="carousel-container" ref={scrollRef}>
      <div className="carousel">
        {images}{images}{images}{images}{images}{images} {images}{images}{images}{images}{images} {images}{images}{images}{images}{images} {images}{images}{images}{images}{images} 
      </div>
    </div>
  );
};

export default ImageCarousel;
