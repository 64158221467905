import React, { useEffect } from 'react'
import AnchorLink from "react-anchor-link-smooth-scroll";
import Header from './Header';
import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from './Footer';
import ImageCarousel from './ImageCarousel';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-scroll';

const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 3000,
    items: 1,
  };
  
  
function Homepage() {
    useEffect(() => {
Aos.init({duration: 2000});
    }, [])
    return (
        <div>
            <Header/>
            <div className="container-fluid gtco-banner-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6" data-aos ="fade-right">
                            <h1> We promise to bring
                                the best <span>solution</span> for
                                your business. </h1>
                            <p> Our team is dedicated to delivering exceptional results tailored to your needs. Experience seamless integration and innovative strategies designed to propel your business forward.
</p>
<Link to="contact" smooth={true} duration={500} className="btn btn-primary" style={{ color: 'white' }}>
        Contact Us <i className="fa fa-angle-right" aria-hidden="true"></i>
    </Link>                            </div>
                        <div className="col-md-6">
                            <div className="card"><img className="card-img-top img-fluid" src="assets/images/banner-img.png" alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid gtco-feature" id="services" style={{paddingTop:'100px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="cover">
                                <div className="card" data-aos="zoom-in-right">
                                    <svg
                                        className="back-bg"
                                        width="100%" viewBox="0 0 900 700"
                                        style={{ position: 'absolute', zIndex: -1 }} >
                                        <defs>
                                            <linearGradient id="PSgrad_01" x1="64.279%" x2="0%" y1="76.604%" y2="0%">
                                                <stop offset="0%" stopColor="rgb(1,230,248)" stopOpacity="1" />
                                                <stop offset="100%" stopColor="rgb(29,62,222)" stopOpacity="1" />
                                            </linearGradient>
                                        </defs>
                                        <path fillRule="evenodd" opacity="0.102" fill="url(#PSgrad_01)"
                                            d="M616.656,2.494 L89.351,98.948 C19.867,111.658 -16.508,176.639 7.408,240.130 L122.755,546.348 C141.761,596.806 203.597,623.407 259.843,609.597 L697.535,502.126 C748.221,489.680 783.967,441.432 777.751,392.742 L739.837,95.775 C732.096,35.145 677.715,-8.675 616.656,2.494 Z" />
                                    </svg>
                                    {/* **************/}

                                    <svg width="100%" viewBox="0 0 700 500">
                                        <clipPath id="clipPath">
                                            <path d="M89.479,0.180 L512.635,25.932 C568.395,29.326 603.115,76.927 590.357,129.078 L528.827,380.603 C518.688,422.048 472.661,448.814 427.190,443.300 L73.350,400.391 C32.374,395.422 -0.267,360.907 -0.002,322.064 L1.609,85.154 C1.938,36.786 40.481,-2.801 89.479,0.180 Z"></path>
                                        </clipPath>
                                        {/* href for modern browsers, src for IE8- */}
                                        <image clipPath="url(#clipPath)" href="assets/images/learn-img.jpg" width="100%"
                                            height="465" className="svg__image"></image>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5" data-aos="zoom-in-left">
                            <h2> At Omniminds,
                            we offer a wide range of cutting-edge solutions </h2>
                            <p> to meet your business needs. From mobile application development for iOS and Android to UI/UX design and web application development,
                            we are committed to delivering user-focused, intuitive, and visually appealing digital experiences.</p>
                            <p>
                                Additionally, our expertise extends to CI/CD and DevOps solutions, Data Engineering, and Artificial Intelligence/Machine Learning, 
                                empowering businesses to streamline their processes, unlock valuable insights, and stay ahead of the competition.
                                
                            </p>
                                


                            <a href="#">Learn More <i className="fa fa-angle-right" aria-hidden="true"></i></a>
                            
                            </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid gtco-features-list" data-aos="fade-right"
     data-aos-offset="10"
     >
                <div className="container">
                    <div className="row">
                        <div className="media col-md-6 col-lg-4" id='cloud'>
                            <div className="oval mr-4"><img className="align-self-start" src="assets/images/cloud-comp.png" alt="" /></div>
                            <div className="media-body">
                                <h5 className="mb-0">Cloud</h5>
                                We are committed to ensuring that your business harnesses the full power of the cloud. Our innovative solutions provide your business with a competitive edge by leveraging the latest technology. Take advantage of our comprehensive suite of cloud-based services, offering easy and affordable access to applications and resources. Let us help you propel your business forward into the future.
                            </div>
                        </div>
                        <div className="media col-md-6 col-lg-4" id='devops'>
                            <div className="oval mr-4"><img className="align-self-start" src="assets/images/software.png" alt="" /></div>
                            <div className="media-body">
                                <h5 className="mb-0">DevOps</h5>
                                Optimize your development lifecycle with our comprehensive CI/CD and DevOps solutions. We offer cost-effective services designed to streamline your development process, ensuring faster delivery of high-quality software. From continuous integration and delivery to automated testing and deployment, our expert team will help you implement best practices that drive efficiency and innovation. Let us help you accelerate your development cycles and stay ahead of the competition.


                            </div>
                        </div>
                        <div className="media col-md-6 col-lg-4" id='web-development'>
                            <div className="oval mr-4"><img className="align-self-start" src="assets/images/user-experience.png" alt="" /></div>
                            <div className="media-body">
                                <h5 className="mb-0">Web Development</h5>
                                Empower your digital presence with our tailored UI/UX and web application development solutions. We offer comprehensive and cost-effective services designed to deliver user-centric, intuitive, and visually appealing web applications. Our expertise ensures that your web applications are seamlessly integrated and consistent across all platforms. Let us help you create digital experiences that engage your audience and drive business growth.


                            </div>
                        </div>
                        <div className="media col-md-6 col-lg-4" id='ai-ml'>
                            <div className="oval mr-4"><img className="align-self-start" src="assets/images/ai.png" alt="" /></div>
                            <div className="media-body">
                                <h5 className="mb-0">AI/ML</h5>
                                Harness the full potential of Artificial Intelligence and Machine Learning with our custom solutions. We offer comprehensive services designed to help you leverage AI and ML technologies to drive business growth. From predictive analytics and recommendation engines to natural language processing and computer vision, our expert team will help you build intelligent solutions that deliver real value. Let us help you transform your business and stay ahead of the curve in today's data-driven world.


                            </div>
                        </div>
                        <div className="media col-md-6 col-lg-4" id='data-engineering'>
                            <div className="oval mr-4"><img className="align-self-start" src="assets/images/ads.png" alt="" /></div>
                            <div className="media-body">
                                <h5 className="mb-0">Data Engineering</h5>
                                Transform your data into actionable insights with our comprehensive Data Engineering solutions. We offer cost-effective services designed to streamline your data pipelines and ensure efficient data processing. From data collection and storage to data transformation and analysis, our expert team will help you build robust and scalable data infrastructure. Let us help you harness the power of your data and drive informed decision-making across your organization.


                            </div>
                        </div>
                        <div className="media col-md-6 col-lg-4" id='mobile-development'>
                            <div className="oval mr-4"><img className="align-self-start" src="assets/images/smartphone.png" alt="" /></div>
                            <div className="media-body">
                                <h5 className="mb-0">Mobile Development</h5>
                                Unlock the full potential of mobile with our comprehensive and cost-effective iOS and Android application design, integration, and management services. We specialize in delivering user-focused, intuitive, and responsive mobile apps that seamlessly integrate with major platforms such as Android and iOS. Let us help you create mobile experiences that captivate your audience and drive results.


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid gtco-why-choose-us" id='why-us' data-aos="zoom-in" style={{paddingTop:'100px'}}><div className="container"><h2 style={{textAlign:"center", fontWeight:"bold"}}>Why Choose Us</h2></div></div>

            <div className="container-fluid gtco-numbers-block" data-aos="zoom-in">
                <div className="container">
                
                    <svg width="100%" viewBox="0 0 1600 400">
                        <defs>
                            <linearGradient id="PSgrad_03" x1="80.279%" x2="0%" y2="0%">
                                <stop offset="0%" stopColor="#00447C" stopOpacity="1" />
                                <stop offset="100%" stopColor="#FF3399" stopOpacity="1" />

                            </linearGradient>

                        </defs>
                        {/* <clipPath id="clipPath3">

                                      </clipPath> */}

                        <path fillRule="evenodd" fill="url(#PSgrad_03)"
                            d="M98.891,386.002 L1527.942,380.805 C1581.806,380.610 1599.093,335.367 1570.005,284.353 L1480.254,126.948 C1458.704,89.153 1408.314,59.820 1366.025,57.550 L298.504,0.261 C238.784,-2.944 166.619,25.419 138.312,70.265 L16.944,262.546 C-24.214,327.750 12.103,386.317 98.891,386.002 Z"></path>

                        <clipPath id="ctm" fill="none">
                            <path
                                d="M98.891,386.002 L1527.942,380.805 C1581.806,380.610 1599.093,335.367 1570.005,284.353 L1480.254,126.948 C1458.704,89.153 1408.314,59.820 1366.025,57.550 L298.504,0.261 C238.784,-2.944 166.619,25.419 138.312,70.265 L16.944,262.546 C-24.214,327.750 12.103,386.317 98.891,386.002 Z"></path>
                        </clipPath>

                        {/* href for modern browsers, src for IE8- */}
                        <image clipPath="url(#ctm)" href="assets/images/word-map.png" height="800px" width="100%" className="svg__image">

                        </image>

                    </svg>
                    <div className="row"  >
                        
                        <div className="col-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <h5 className="card-title">13+ </h5>
                                    <p className="card-text">Talented Staff</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="card text-center">
                                <div className="card-body">
                                    <h5 className="card-title">6+</h5>
                                    <p className="card-text">Happy Clients</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="card text-center">
                                <div className="card-body" >
                                    <h5 className="card-title" >100%</h5>
                                    <p className="card-text">Job Success Score</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="card text-center">
                                <div className="card-body">
                                    <h5 className="card-title">10+</h5>
                                    <p className="card-text">Years of Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid gtco-features"  data-aos="zoom-in"
      id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h2> What Makes Us Different<br />
                                from others  </h2>
                            <p> We provide innovative cloud solutions to give your business a competitive edge, with easy and affordable access to advanced technology and resources for future growth.
</p>
                            <a href="#">All Services <i className="fa fa-angle-right" aria-hidden="true"></i></a></div>
                        <div className="col-lg-8">
                            <svg id="bg-services"
                                width="100%"
                                viewBox="0 0 1000 800">
                                <defs>
                                    <linearGradient id="PSgrad_02" x1="64.279%" x2="0%" y1="76.604%" y2="0%">
                                        <stop offset="0%" stopColor="rgb(1,230,248)" stopOpacity="1" />
                                        <stop offset="100%" stopColor="rgb(29,62,222)" stopOpacity="1" />
                                    </linearGradient>
                                </defs>
                                <path fillRule="evenodd" opacity="0.102" fill="url(#PSgrad_02)"
                                    d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z" />
                            </svg>
                            <div className="row">
                                <div className="col">
                                    <div className="card text-center">
                                        <div className="oval"><img className="card-img-top" src="assets/images/web-design.png" alt="" /></div>
                                        <div className="card-body">
                                            <h3 className="card-title">Innovated Thinking</h3>
                                            <p className="card-text">Constantly strive for innovation in products, services, and processes. Being at the forefront of technological advancements allows a company to meet evolving market needs and stay ahead of the competition.</p>
                                        </div>
                                    </div>
                                    <div className="card text-center">
                                        <div className="oval"><img className="card-img-top" src="assets/images/marketing.png" alt="" /></div>
                                        <div className="card-body">
                                            <h3 className="card-title">Customer-Centric Approach</h3>
                                            <p className="card-text">Prioritize customer satisfaction by understanding their needs and delivering solutions that add value. Excellent customer service, user-friendly interfaces to a positive customer experience.

</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col" data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
                                    <div className="card text-center">
                                        <div className="oval"><img className="card-img-top" src="assets/images/seo.png" alt="" /></div>
                                        <div className="card-body">
                                            <h3 className="card-title">Talent and Collaboration</h3>
                                            <p className="card-text">Attract and retain top-tier talent to drive technological excellence. A skilled and motivated workforce is essential for developing cutting-edge solutions in a rapidly changing tech landscape.</p>
                                        </div>
                                    </div>
                                    <div className="card text-center">
                                        <div className="oval"><img className="card-img-top" src="assets/images/graphics-design.png" alt="" /></div>
                                        <div className="card-body">
                                            <h3 className="card-title">Sustainability and Responsibility</h3>
                                            <p className="card-text">Commit to sustainable practices and corporate responsibility. Embrace environmentally friendly technologies and ethical business practices to contribute positively to society and the environment.

</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container-fluid gtco-testimonials" data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500">
                <div className="container">
                    <h2>What Our Customers Say About Us</h2>
                    <OwlCarousel className="owl-theme" {...options}>
                        <div>
                            <div className="card text-center"><img className="card-img-top" src="assets/images/jared.jpeg" alt="" />
                                <div className="card-body">
                                    <h5>Jared Egget<br />
                                        <span> CEO, Moment Track </span></h5>
                                    <p className="card-text">“ This is a diamond in the rough. if you ever get a chance to work with them you should do so. ” </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card text-center"><img className="card-img-top" src="assets/images/Brain.png" alt="" />
                                <div className="card-body">
                                    <h5>Brian Hill<br />
                                        <span> CEO, Glacier Analytics </span></h5>
                                    <p className="card-text">“ Omniminds has a broad range of experience and skills. Their communication and management skills are excellent. They delivered great results in a timely manner. I highly recommend Omniminds. ” </p>
                                </div>
                            </div>
                        </div>
                        
                    </OwlCarousel>
                </div>
            </div>
            <div className="container-fluid gtco-why-choose-us" id='we-are-using' style={{paddingTop:'100px'}}><div className="container"><h2 style={{textAlign:"center", fontWeight:"bold"}}>Proficient in Following Platforms</h2></div></div>

            
            <div className="container-fluid ">
                <ImageCarousel/>
            </div>
            
            
            <Footer/>
        </div>
    )
}

export default Homepage