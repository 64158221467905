import React, { useEffect } from 'react';
import { Link } from 'react-scroll';
import Aos from 'aos'
import 'aos/dist/aos.css'

const Footer = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
        }, [])

  return (
    <footer className="container-fluid" id="gtco-footer" data-aos="fade-up">
      <div className="container">
        <div className="row">
          <div className="col-lg-6" id="contact">
            <h4>Contact Us</h4>
            <input type="text" className="form-control mb-2" placeholder="Full Name" />
            <input type="email" className="form-control mb-2" placeholder="Email Address" />
            <textarea className="form-control mb-2" placeholder="Message"></textarea>
            <a href="#" className="btn btn-primary">
              Submit <i className="fa fa-angle-right ml-2" aria-hidden="true"></i>
            </a>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-6">
                <h4>Company</h4>
                <ul className="nav flex-column company-nav">
                  <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="gtco-main-nav" // ID of the element at the top of your page
                    smooth={true}
                    duration={500}
                    spy={true} // This will update the active class on navigation
                    exact='true' // This ensures the link is only active if it matches exactly
                    offset={-70} // Offset for fixed header if you have one
                  >
                    <p className='footer-menu-item'>Home</p>
                  </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="services" smooth={true} duration={500}>
                    <p className='footer-menu-item'>Services We Offer</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="about" smooth={true} duration={500}>
                    <p className='footer-menu-item'>Why Us?</p>

                    </Link>
                  </li>
                  
                  
                  <li className="nav-item">
                    <Link className="nav-link" to="contact" smooth={true} duration={500}>
                    <p className='footer-menu-item'>Contact</p>
                    </Link>
                  </li>
                </ul>
                <h4 className="mt-5">Contact Details</h4>
                <p>Madhyapara, Ayas, Birbhum, West Bengal, India, PIN - 731243</p>
                <p>Phone: <a href="tel:+919332316718">+91 9332316718</a></p>
    <p>Email: <a href="mailto:sales@omnimindsconsulting.com">sales@omnimindsconsulting.com</a></p>
                
                
              </div>
              
              <div className="col-6">
                <h4>Services</h4>
                <ul className="nav flex-column services-nav">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="cloud" // ID of the target section
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-70}
                    >
                      <p className='footer-menu-item'>Cloud</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="devops"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-70}
                    >
                      <p className='footer-menu-item'>DevOps</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="web-development"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-70}
                    >
                      <p className='footer-menu-item'>Web Development</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="ai-ml"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-70}
                    >
                      <p className='footer-menu-item'>AI/ML</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="data-engineering"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-70}
                    >
                      <p className='footer-menu-item'>Data Engineering</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="mobile-development"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-70}
                    >
                      <p className='footer-menu-item'>Mobile Development</p>
                    </Link>
                  </li>
                </ul>
                <h4 className="mt-5">Follow Us</h4>
                <ul className="nav follow-us-nav">
                  <li className="nav-item">
                    <a className="nav-link pl-0" href="#">
                    <p className='footer-menu-item'><i className="fa fa-facebook" aria-hidden="true"></i></p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                    <p className='footer-menu-item'><i className="fa fa-twitter" aria-hidden="true"></i></p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                    <p className='footer-menu-item'><i className="fa fa-google" aria-hidden="true"></i></p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                    <p className='footer-menu-item'><i className="fa fa-linkedin" aria-hidden="true"></i></p>
                    </a>
                  </li>
                </ul>
              </div>
              
              <div className="col-12">
                <p>All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
